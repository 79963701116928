import { useEffect, useState } from 'react';
// import axios from 'axios';
import polylineFn from '@mapbox/polyline';
import StaticMap, { defaultAccessToken } from './StaticMap';
import type { PathProps, MarkerProps } from './StaticMap';
interface RouteStaticMapProps {
	width: number;
	height: number;
	markers: MarkerProps[];
	path?: PathProps;
	accessToken?: string;
	imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
	callback?: (data: any) => void;
}

type Point = { x: number; y: number };

// const directionApiURL = 'https://api.mapbox.com/directions/v5';
// const profile = 'mapbox/driving';

const RouteStaticMap = ({
	width,
	height,
	markers,
	path,
	imgProps,
	accessToken,
	callback,
}: RouteStaticMapProps) => {
	const [polyline, setPolyline] = useState('');
	// const markerStr = getMarkerStr(markers);

	useEffect(() => {
		if (polyline) {
			setPolyline('');
		}
		let points: any = [];
		markers.forEach((marker, index) => {
			if (index < markers.length - 1) {
				points.push([marker.lat, marker.lng]);
				const nextMarker = markers[index + 1];
				const start = { x: marker.lat, y: marker.lng };
				const end = { x: nextMarker.lat, y: nextMarker.lng };
				const point = getCenterPoint(start, end);
				points = points.concat(
					getBezierCurve(start, end, point, 20).map(({ x, y }) => [x, y])
				);

				points.push([nextMarker.lat, nextMarker.lng]);
			}
		});

		// dedupe
		points = points.map((x: any) => JSON.stringify(x));
		points = Array.from(new Set(points));
		points = points.map((x: any) => JSON.parse(x));

		// 超級bug
		setPolyline(encodeURI(polylineFn.encode(points)).replace(/\?/g, '%3F'));
		// const init = async () => {
		// 	try {
		// 		const resp = await axios.get(
		// 			`${directionApiURL}/${profile}/${markerStr}?geometries=geojson&access_token=${accessToken}`
		// 		);
		// 		const { data } = resp;
		// 		setPolyline(polylineFn.fromGeoJSON(data.routes[0].geometry));
		// 		if (callback) {
		// 			callback(data);
		// 		}
		// 	} catch (e) {
		// 		if (callback) {
		// 			callback({ error: e });
		// 		}
		// 	}
		// };
		// init();
	}, [JSON.stringify(markers)]);
	if (!polyline) return null;
	return (
		<StaticMap
			width={width}
			height={height}
			markers={markers}
			path={{
				...path,
				polyline,
			}}
			imgProps={imgProps}
			accessToken={accessToken}
		/>
	);
};

RouteStaticMap.defaultProps = {
	accessToken: defaultAccessToken,
};

// function getMarkerStr(markers: MarkerProps[]) {
// 	return markers.map(({ lat, lng }) => `${lng},${lat}`).join(';');
// }

export default RouteStaticMap;

const getBezierPoint = (
	start: Point,
	end: Point,
	control: Point,
	t: number
) => {
	const x =
		(1 - t) * (1 - t) * start.x + 2 * t * (1 - t) * control.x + t * t * end.x;
	const y =
		(1 - t) * (1 - t) * start.y + 2 * t * (1 - t) * control.y + t * t * end.y;
	return { x, y };
};

const getBezierCurve = (
	start: Point,
	end: Point,
	control: Point,
	steps: number
) => {
	const points = [];
	const step = 1 / steps;
	for (let i = 0; i <= 1; i += step) {
		points.push(getBezierPoint(start, end, control, i));
	}
	return points;
};

function distance(p1: Point, p2: Point) {
	return Math.sqrt(
		(p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y)
	);
}

function midpoint(p1: Point, p2: Point) {
	return {
		x: (p1.x + p2.x) / 2,
		y: (p1.y + p2.y) / 2,
	};
}

function lineSlope(p1: Point, p2: Point) {
	return Math.abs(p2.x - p1.x) === 0 ? Infinity : (p2.y - p1.y) / (p2.x - p1.x);
}

function verticalBisectorEquation(start: Point, end: Point) {
	const center = midpoint(start, end);
	const k1 = lineSlope(start, end);
	const k = k1 === Infinity ? 0 : -1 / k1;
	const b = center.y - k * center.x;
	return { k, b, x: k1 === Infinity ? start.x : NaN } as any;
}

function getCenterPoint(p1: Point, p2: Point) {
	const slope = -1 / lineSlope(p1, p2);
	const verticalLineEquation = verticalBisectorEquation(p1, p2);
	const midPoint = midpoint(p1, p2);
	let d = distance(p1, p2);
	const { k, b } = verticalLineEquation;
	let point;

	// 用斜率大小決定center point是用垂直平分線上與起點或終點的x或y座標
	if (Math.abs(slope) > 1) {
		if (p1.y > p2.y) {
			// 起點在右邊
			if (p1.x > p2.x) {
				// 起點在上
				d = -d;
				point = { x: (p2.y - b) / k, y: p2.y };
			} else {
				// 起點在下
				point = { x: (p2.y - b) / k, y: p2.y };
			}
		} else {
			// 起點在左邊
			if (p1.x > p2.x) {
				// 起點在上
				point = { x: (p1.y - b) / k, y: p1.y };
			} else {
				d = -d;
				// 起點在下
				point = { x: (p1.y - b) / k, y: p1.y };
			}
		}
	} else {
		if (p1.y > p2.y) {
			// 起點在右邊
			if (p1.x > p2.x) {
				// 起點在上
				d = -d;
				point = { x: p1.x, y: k * p1.x + b };
			} else {
				// 起點在下
				d = -d;
				point = { x: p2.x, y: k * p2.x + b };
			}
		} else {
			// 起點在左邊
			if (p1.x > p2.x) {
				// 起點在上
				d = -d;
				point = { x: p1.x, y: k * p1.x + b };
			} else {
				d = -d;
				// 起點在下
				point = { x: p2.x, y: k * p2.x + b };
			}
		}
	}

	// 斜率太大 增加中心點偏移量
	if (Math.abs(slope) > 3) {
		point = getDeviationPoint(midPoint, point, d / 4);
	}

	// // 斜率太小 增加中心點偏移量
	if (Math.abs(slope) < 0.3) {
		point = getDeviationPoint(midPoint, point, d / 4);
	}

	// 斜率例外處理 暫時為直線
	if (slope === Infinity || slope === 0) {
		point = midPoint;
	}
	return point;
}

function getDeviationPoint(p1: any, p2: any, d: number) {
	const slope = lineSlope(p1, p2);
	const theta = Math.atan(slope);
	const midPoint = midpoint(p1, p2);
	const point = {
		x: midPoint.x - d * Math.cos(theta),
		y: midPoint.y - d * Math.sin(theta),
	};
	return point;
}
