import type { Components } from '@mui/material/styles/components';

const MuiInputLabel: Components['MuiInputLabel'] = {
	styleOverrides: {
		root: {
			'& p': {
				color: 'inherit !important',
			},
			'& label .MuiSvgIcon-root': {
				pointerEvents: 'auto',
			},
		},
	},
};

export default MuiInputLabel;
