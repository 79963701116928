import { alpha } from '@mui/material/styles';
import type { Components } from '@mui/material/styles/components';
import { BLACK, WHITE, ERROR } from '~/theme/colors';

const MuiButton: Components['MuiButton'] = {
	styleOverrides: {
		root: {
			borderRadius: '8px',
			fontFamily: 'PingFang TC',
			cursor: 'pointer',
			boxShadow: `0px 4px 8px 0px ${alpha(BLACK, 0.1)}`,
			'&.Mui-disabled': {
				boxShadow: 'none',
				pointerEvents: 'auto',
				cursor: 'not-allowed',
				background: 'inherit',
			},
			'&.MuiButton-sizeMedium': {
				height: '40px',
			},
			'&.MuiButton-sizeLarge': {
				height: '56px',
				fontSize: '16px',
			},
		},
	},
	variants: [
		{
			props: { variant: 'contained', color: 'primary' },
			style: {
				color: WHITE,
				background: BLACK,
				'&:hover': {
					color: WHITE,
					background: alpha(BLACK, 0.6),
				},
				'&:disabled': {
					color: WHITE,
					background: alpha(BLACK, 0.2),
				},
			},
		},
		{
			props: { variant: 'alert', color: 'primary' },
			style: {
				color: WHITE,
				background: ERROR,
				'&:hover': {
					color: WHITE,
					background: alpha(ERROR, 0.6),
				},
				'&:disabled': {
					color: WHITE,
					boxShadow: 'none',
					background: alpha(ERROR, 0.2),
				},
			},
		},
		{
			props: { variant: 'outlined', color: 'primary' },
			style: {
				color: BLACK,
				borderColor: BLACK,
				'&:hover': {
					color: alpha(BLACK, 0.6),
					borderColor: alpha(BLACK, 0.6),
					background: 'inherit',
				},
				'&:disabled': {
					color: alpha(BLACK, 0.2),
				},
			},
		},
		{
			props: { variant: 'outlined', color: 'error' },
			style: {
				color: ERROR,
				borderColor: ERROR,
				'&:hover': {
					color: alpha(ERROR, 0.6),
					borderColor: alpha(ERROR, 0.6),
					background: 'inherit',
				},
				'&:disabled': {
					color: alpha(ERROR, 0.2),
					borderColor: alpha(ERROR, 0.2),
				},
			},
		},
		{
			props: { variant: 'ghost', color: 'primary' },
			style: {
				color: BLACK,
				fontWeight: 500,
				background: WHITE,
				'&:hover': {
					color: alpha(BLACK, 0.6),
					background: WHITE,
				},
				'&:disabled': {
					color: alpha(BLACK, 0.2),
					background: WHITE,
					boxShadow: `0px 4px 8px 0px ${alpha(BLACK, 0.1)}`,
				},
			},
		},
		{
			props: { variant: 'text', color: 'primary' },
			style: {
				color: BLACK,
				boxShadow: 'none',
				'&:hover': {
					color: alpha(BLACK, 0.6),
					background: 'inherit',
				},
				'&:disabled': {
					color: alpha(BLACK, 0.2),
				},
			},
		},
		{
			props: { variant: 'text', color: 'error' },
			style: {
				color: ERROR,
				boxShadow: 'none',
				'&:hover': {
					color: alpha(ERROR, 0.6),
					background: 'inherit',
				},
				'&:disabled': {
					color: alpha(BLACK, 0.2),
				},
			},
		},
	],
};

export default MuiButton;
