import { forwardRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';
import InputField from './InputField';

interface ComponentProps {
	onChange: Function;
	name: string;
}

const InputMask = forwardRef<HTMLInputElement, ComponentProps>(
	({ onChange, name, ...props }, ref) => {
		const [touched, setTouched] = useState(false);
		return (
			<IMaskInput
				{...props}
				name={name}
				mask='0000-000-000'
				onAccept={(value: any) => {
					if (touched) {
						onChange({ target: { name, value } });
					}
				}}
				overwrite
				style={{}}
				inputRef={ref as any}
				onFocus={() => {
					if (!touched) {
						setTouched(true);
					}
				}}
			/>
		);
	}
);

const PhoneInputField = ({ label, placeholder, InputProps, ...props }: any) => {
	const { t } = useTranslation();
	return (
		<InputField
			{...props}
			label={label ?? t('mobile')}
			placeholder={placeholder ?? t('mobile.placeholder')}
			InputProps={{
				...InputProps,
				inputComponent: InputMask as any,
			}}
		/>
	);
};

PhoneInputField.defaultProps = {
	name: 'phoneNumber',
};
export default PhoneInputField;
