import { ReactNode } from 'react';
import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import type { TypographyTypeMap } from '@mui/material/Typography';

interface TitleProps {
	color: string;
	variant: TypographyTypeMap['props']['variant'];
	children: ReactNode;
	titleSx?: SxProps;
}

const Title = ({ color, variant, children, titleSx }: TitleProps) => {
	return (
		<Typography
			variant={variant}
			color={color}
			sx={{
				fontWeight: 'medium',
				mb: 1.5,
				...titleSx,
			}}
			gutterBottom
		>
			{children}
		</Typography>
	);
};

Title.defaultProps = {
	color: 'primary',
	variant: 'h5',
};

export default Title;
