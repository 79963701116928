import { lazy } from 'react';

const ContainerAdminPage = lazy(
	() =>
		import('./ContainerAdminPage' /* webpackChunkName: 'ContainerAdminPage' */)
);

const ContainerShipperPage = lazy(
	() =>
		import(
			'./ContainerShipperPage' /* webpackChunkName: 'ContainerShipperPage' */
		)
);

const ContainerCarrierPage = lazy(
	() =>
		import(
			'./ContainerCarrierPage' /* webpackChunkName: 'ContainerCarrierPage' */
		)
);

const ContainerShippingOrderPage = lazy(
	() =>
		import(
			'./ShippingOrder/ContainerShippingOrderPage' /* webpackChunkName: 'ContainerShippingOrderPage' */
		)
);

const ContainerShippingOrderDetailPage = lazy(
	() =>
		import(
			'./ShippingOrderDetail/ContainerShippingOrderDetailPage' /* webpackChunkName: 'ContainerShippingOrderDetailPage' */
		)
);

const ContainerShipmentDetailPage = lazy(
	() =>
		import(
			'./ShipmentDetail/ContainerShipmentDetailPage' /* webpackChunkName: 'ContainerShippingOrderDetailPage' */
		)
);

export {
	ContainerAdminPage,
	ContainerShipperPage,
	ContainerCarrierPage,
	ContainerShippingOrderPage,
	ContainerShippingOrderDetailPage,
	ContainerShipmentDetailPage,
};
