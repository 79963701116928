import { useMemo } from 'react';
import type { RouteObject } from 'react-router-dom';
import useSWR from 'swr';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import useSWRState from '~/hooks/useSWRState';
import { isAdminURL } from '~/utils/urls';
import getRolePath from '~/utils/getRolePath';
import { IMe } from '~/types/api/me';
import allRoutes from '../routes';

const NOT_ALLOWED_PATHS = ['/', '*'];

const useConfigRoutes = () => {
	const apiUrl = useMemo(() => getRolePath('/me/$ROLE-user'), []);
	const { data: isLogin } = useSWRState('isLogin');
	const { data: userInfo, mutate } = useSWR<IMe>(apiUrl, {
		revalidateIfStale: false,
	});
	const routes = useMemo(() => {
		if (!isLogin) return allRoutes;
		if (userInfo) {
			const routeShippingType = isAdminURL
				? userInfo.featuresToggle
				: userInfo.shippingType;
			return filterShippingType(allRoutes, routeShippingType);
		}
		return allRoutes.filter(
			(route) => !NOT_ALLOWED_PATHS.includes(route.path ?? '')
		);
	}, [userInfo, isLogin]);
	useUpdateEffect(() => {
		if (isLogin) {
			mutate();
		}
	}, [isLogin]);
	return routes;
};

function filterShippingType(currRoutes: RouteObject[], routeShippingType: any) {
	routeShippingType.pallet =
		routeShippingType.pallet ||
		routeShippingType.box ||
		routeShippingType.vehicle;
	const newRoutes: RouteObject[] = [];
	currRoutes.forEach((routeObject) => {
		if (
			!routeObject.shippingType ||
			routeShippingType[routeObject.shippingType]
		) {
			if (Array.isArray(routeObject.children)) {
				routeObject.children = filterShippingType(
					routeObject.children,
					routeShippingType
				);
			}
			newRoutes.push(routeObject);
		}
	});

	return newRoutes;
}

export default useConfigRoutes;
