import type { Components } from '@mui/material/styles/components';

const MuiFormControl: Components['MuiFormControl'] = {
	styleOverrides: {
		root: {
			borderRadius: '8px',
		},
	},
};

export default MuiFormControl;
