import {
	getUnixTime,
	fromUnixTime,
	startOfDay,
	endOfDay,
	startOfMonth,
	endOfMonth,
	secondsToMilliseconds,
	millisecondsToSeconds,
	hoursToSeconds,
	minutesToSeconds,
	secondsInHour,
	secondsInMinute,
} from 'date-fns';
import { zhTW } from 'date-fns/locale';
import { utcToZonedTime, zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import i18next from 'i18next';
import { NODATA_TEXT } from '~/enums/view';

type timestamp = number;

export const defaultTimeZone = 'Asia/Taipei';
export const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

interface IConvertTimeZoneUnixTimeOptions {
	inputTimeZone?: string;
	outputTimeZone?: string;
	isStartDate?: boolean;
	isEndDate?: boolean;
}

export const convertTimeZoneUnixTime = (
	unixTime: number,
	options: IConvertTimeZoneUnixTimeOptions = {}
) => {
	const { inputTimeZone = localTimeZone, outputTimeZone = defaultTimeZone } =
		options;
	let timeZoneDate = utcToZonedTime(
		secondsToMilliseconds(unixTime),
		inputTimeZone
	);
	const timeZoneUtcDate = zonedTimeToUtc(timeZoneDate, outputTimeZone);
	return getUnixTime(timeZoneUtcDate);
};

export const toTime = (t: timestamp, timeZone: string = defaultTimeZone) =>
	formatInTimeZone(fromUnixTime(t), timeZone, 'HH:mm');
export const toDate = (t: timestamp, timeZone: string = defaultTimeZone) =>
	formatInTimeZone(fromUnixTime(t), timeZone, 'yyyy/MM/dd');
export const toDateFormat = (
	t: timestamp,
	formatString: string,
	timeZone: string = defaultTimeZone
) => formatInTimeZone(fromUnixTime(t), timeZone, formatString);

export const toDateTime = (t: timestamp, timeZone: string = defaultTimeZone) =>
	formatInTimeZone(fromUnixTime(t), timeZone, 'yyyy/MM/dd HH:mm');
export const toDateTimeSecond = (
	t: timestamp,
	timeZone: string = defaultTimeZone
) => formatInTimeZone(fromUnixTime(t), timeZone, 'yyyy/MM/dd HH:mm:ss');

export const toDateTimePeriod = (
	start: timestamp,
	end: timestamp,
	timeZone: string = defaultTimeZone
) =>
	formatInTimeZone(fromUnixTime(start), timeZone, 'yyyy/MM/dd HH:mm') +
	'-' +
	formatInTimeZone(fromUnixTime(end), timeZone, 'HH:mm');

export const i18nyyyy = (t: timestamp, timeZone: string = defaultTimeZone) =>
	formatInTimeZone(fromUnixTime(t), timeZone, `yyyy ${i18next.t('year')}`);

export const i18nMMdd = (t: timestamp, timeZone: string = defaultTimeZone) =>
	formatInTimeZone(
		fromUnixTime(t),
		timeZone,
		`MM ${i18next.t('month')} dd ${i18next.t('day')}`
	);
export const i18nMMddHHmm = (
	t: timestamp,
	timeZone: string = defaultTimeZone
) =>
	formatInTimeZone(
		fromUnixTime(t),
		timeZone,
		`MM ${i18next.t('month')} dd ${i18next.t('day')} HH:mm`
	);
export const secondsToHourMinutesText = (
	seconds?: number | string,
	nullText: string = NODATA_TEXT
) => {
	if (!seconds && seconds !== 0) return nullText;
	const s = Number(seconds);
	const h = Math.floor(s / 3600);
	const m = Math.floor((s % 3600) / 60);
	return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
};

export const getFirstDayOfMonth = (y: number, m: number) => {
	return millisecondsToSeconds(+startOfMonth(new Date(y, m)));
};

export const getLastDayOfMonth = (y: number, m: number) => {
	return millisecondsToSeconds(+endOfMonth(new Date(y, m)));
};

export const getStartDateUnixTime = (date: Date) => {
	return getUnixTime(startOfDay(date));
};

export const getEndDateUnixTime = (date: Date) => {
	return getUnixTime(endOfDay(date));
};

export function serverTimeToFormData(unixTime: number) {
	const timeZoneDate = utcToZonedTime(
		secondsToMilliseconds(unixTime),
		defaultTimeZone
	);
	const hours = timeZoneDate.getHours();
	const minutes = timeZoneDate.getMinutes();
	return {
		date: getUnixTime(startOfDay(timeZoneDate)),
		seconds: String(hoursToSeconds(hours) + minutesToSeconds(minutes)),
	};
}

export const startOfTodayUnixTime = getUnixTime(startOfDay(new Date()));
export const convertToApiFormatDate = (inputDate: number) => {
	return convertTimeZoneUnixTime(inputDate, {
		inputTimeZone: localTimeZone,
		outputTimeZone: defaultTimeZone,
	});
};

export const getWeekdayText = (
	t: timestamp,
	timeZone: string = localTimeZone
) =>
	formatInTimeZone(t, timeZone, 'ccc', {
		locale: zhTW,
	});

export const toDateTimeWeekday = (
	t: timestamp,
	timeZone: string = localTimeZone
) =>
	formatInTimeZone(t, timeZone, 'yyyy/MM/dd ccc HH:mm', {
		locale: zhTW,
	});

export const secondsToTimeString = (seconds: number) => {
	const hours = Math.floor(seconds / secondsInHour);
	const minutes = Math.floor((seconds % secondsInHour) / secondsInMinute);
	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
		2,
		'0'
	)}`;
};
