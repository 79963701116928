import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import type { OptionsObject } from 'notistack';
import Snackbar from '~/components/Snackbar';

type OptionsType = OptionsObject &
	Partial<{ variant: 'success' | 'error' | 'warning' | 'info' }>;

const useEnqueueSnackbar = () => {
	const { enqueueSnackbar } = useSnackbar();
	const pushSnackbar = useCallback(
		(message: string | undefined, options?: OptionsType) => {
			const { variant } = options ?? {};
			enqueueSnackbar(message, {
				content: (key) => {
					return (
						<Snackbar key={key} severity={variant}>
							{message}
						</Snackbar>
					);
				},
			});
		},
		[enqueueSnackbar]
	);

	return pushSnackbar;
};

export default useEnqueueSnackbar;
