import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import useConfigRoutes from '~/hooks/useConfigRoutes';
import '~/libraries/schema';
import './App.css';

function App(): JSX.Element {
	const routes = useConfigRoutes();
	const element = useRoutes(routes);
	return <Suspense fallback={null}>{element}</Suspense>;
}

export default App;
