export interface PathProps {
	strokeWidth?: number;
	strokeColor?: string;
	strokeOpacity?: string;
	polyline: string;
}
export interface MarkerProps {
	lat: number;
	lng: number;
	name?: string;
	label?: string;
	color?: string;
	iconURL?: string;
}
interface StaticMapProps {
	width: number;
	height: number;
	accessToken?: string;
	stylePath?: string;
	path?: PathProps;
	markers: MarkerProps[];
	imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const mapboxURL = 'https://api.mapbox.com/styles/v1';
export const defaultAccessToken =
	'pk.eyJ1IjoiZGV2LWFsbHl0cmFuc3BvcnQiLCJhIjoiY2t5djZuYmx6MXVqMzJ2bnUyejZneGs0NyJ9.h0qh0r1YSlznTmcjX0_0fA';
const defaultStylePath = '/dev-allytransport/cl11s10hj003715p6kqpaxlj9';

const StaticMap = ({
	width,
	height,
	stylePath,
	path,
	markers,
	accessToken,
	imgProps,
}: StaticMapProps) => {
	const markersStr = markers.reduce((acc, marker, index) => {
		acc = `${acc}${index ? ',' : ''}${getMarkerStr(marker)}`;
		return acc;
	}, '');
	const pathStr = getPathStr(path);
	const staticImage = `${mapboxURL}${stylePath}/static/${pathStr}${markersStr}/${getZoom(
		markers
	)}/${width}x${height}@2x?access_token=${accessToken}`;
	return (
		<img
			{...imgProps}
			src={staticImage}
			width={width}
			height={height}
			alt={`static-map-${width}x${height}`}
		/>
	);
};

StaticMap.defaultProps = {
	accessToken: defaultAccessToken,
	stylePath: defaultStylePath,
};

function getPathStr(path: PathProps | undefined) {
	if (!path) return '';
	const {
		strokeWidth = 3,
		strokeColor = '231916',
		strokeOpacity = '1',
		polyline,
	} = path;
	// path-{strokeWidth}+{strokeColor}-{strokeOpacity}+{fillColor}-{fillOpacity}({polyline})
	return `path-${strokeWidth}+${strokeColor}-${strokeOpacity}(${polyline}),`;
}

function getMarkerStr({
	lat,
	lng,
	name = 'pin-s',
	label,
	color,
	iconURL,
}: MarkerProps) {
	if (iconURL) {
		// url-{url}({lon},{lat})
		return `url-${encodeURIComponent(iconURL)}(${lng},${lat})`;
	}
	// {name}-{label}+{color}({lon},{lat})
	return `${name}${label ? `-${label}` : ''}${
		color ? `+${color}` : ''
	}(${lng},${lat})`;
}

function getZoom(markers: MarkerProps[]) {
	if (markers.length > 1) return 'auto';
	const { lat, lng } = markers[0];
	return `${lng},${lat},15`;
}

export default StaticMap;
