import type { Components } from '@mui/material/styles/components';

const MuiTablePagination: Components['MuiTablePagination'] = {
	styleOverrides: {
		root: {
			display: 'flex',
			justifyContent: 'center',
			'& .MuiToolbar-root': {
				padding: 0,
				marginTop: '-6px',
			},
			'& .MuiTablePagination-spacer': {
				display: 'none',
			},
			'& .MuiButtonBase-root': {
				'&:hover': {
					backgroundColor: 'color.background',
				},
			},
		},
	},
};

export default MuiTablePagination;
