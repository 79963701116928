import { Box } from '@mui/material';

interface LoadingProps {
	color?: string;
	size?: string;
}

const Loading = ({ color = 'color.black', size = 'small' }: LoadingProps) => {
	const styles = useStyles({ color, size });
	return (
		<Box sx={styles.container}>
			<Box sx={styles.content} />
		</Box>
	);
};

const useStyles = ({ color, size }: { color: string; size: string }) => {
	const width = size === 'small' ? '16px' : '40px';
	const borderWidth = size === 'small' ? '2px' : '4px';
	return {
		container: {
			padding: '4px 0',
		},
		content: {
			border: `${borderWidth} solid`,
			borderColor: color,
			animation: 'spin 1s linear infinite',
			borderTop: `${borderWidth} solid`,
			borderTopColor: 'transparent',
			borderRadius: '50%',
			width,
			height: width,
			'@keyframes spin': {
				'0%': {
					transform: 'rotate(0deg)',
				},
				'100%': {
					transform: 'rotate(360deg)',
				},
			},
		},
	};
};

export default Loading;
