const BLACK = '#231916';
const ERROR = '#CD2A01';
const WARNING = '#2454FF';
const BACKGROUND = '#F5F5F5';
const DIVIDER = '#DFDFDF';
const SUCCESS = '#42A68E';
const WHITE = '#FFFFFF';
const BLUE = '#356898';
const TAG_BLUE = '#C3D1DE';
const ALLY_BLUE = '#2454FF';
const BACKGROUND_BLUE = '#EEF3F8';
const BACKGROUND_GRAY = '#F5F5F5';
const BACKGROUND_BLACK = '#4F4745';
const BACKGROUND_WHITE = '#F9F9F9';

export {
	BLACK,
	ERROR,
	WARNING,
	BACKGROUND,
	DIVIDER,
	SUCCESS,
	WHITE,
	BLUE,
	TAG_BLUE,
	ALLY_BLUE,
	BACKGROUND_BLUE,
	BACKGROUND_GRAY,
	BACKGROUND_BLACK,
	BACKGROUND_WHITE,
};
