import React from 'react';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

const TextField = React.forwardRef(({ sx, ...props }: TextFieldProps, ref) => (
	<MuiTextField
		{...props}
		size='small'
		inputRef={ref}
		sx={{ bgcolor: 'white', ...sx }}
	/>
));

export default TextField;
