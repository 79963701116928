import type { Components } from '@mui/material/styles/components';

const MuiFormHelperText: Components['MuiFormHelperText'] = {
	styleOverrides: {
		root: {
			fontSize: '12px',
			lineHeight: '17px',
			marginLeft: 0,
		},
	},
};

export default MuiFormHelperText;
