// chatGPT generate
function isMobile() {
	const userAgent = navigator.userAgent.toLowerCase();
	const mobileKeywords = [
		'mobile',
		'android',
		'iphone',
		'ipod',
		'ipad',
		'windows phone',
	];

	for (let i = 0; i < mobileKeywords.length; i++) {
		if (userAgent.indexOf(mobileKeywords[i]) > -1) {
			return true;
		}
	}

	return false;
}

export default isMobile;
