import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

interface UseSWRStateResponse<Data> {
	data: Data;
	trigger: (data: Data) => void;
}

const useSWRState = <Data>(key: string): UseSWRStateResponse<Data> => {
	const { mutate } = useSWRConfig();
	const { data } = useSWRImmutable(key, null);

	const trigger = useCallback(
		(value) => mutate(key, value, false),
		[key, mutate]
	);

	return { data, trigger };
};

export default useSWRState;
