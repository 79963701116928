import { useMemo } from 'react';
import {
	Button as MuiButton,
	ButtonProps as MuiButtonProps,
} from '@mui/material';
import { SxProps } from '@mui/system';
import Loading from './Loading';
export interface ButtonProps extends Omit<MuiButtonProps, 'onClick'> {
	onClick?: any;
	sx?: SxProps;
	loading?: boolean;
}

const Button = ({
	children,
	onClick,
	sx,
	loading,
	variant = 'contained',
	type = 'button',
	size = 'medium',
	color = 'primary',
	disabled,
	...props
}: ButtonProps) => {
	const loadingColor = useMemo(() => {
		switch (variant) {
			case 'contained':
				return 'color.white';
			case 'outlined':
				return color === 'error' ? 'color.error' : 'color.black';
			default:
				return 'color.black';
		}
	}, [variant]);

	return (
		<MuiButton
			onClick={onClick}
			sx={{
				textTransform: 'none',
				cursor: loading ? 'not-allowed' : 'pointer',
				...sx,
			}}
			variant={variant}
			type={type}
			size={size}
			color={color}
			disabled={disabled}
			{...props}
		>
			{loading ? <Loading color={loadingColor} /> : children}
		</MuiButton>
	);
};

export default Button;
