import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
interface IBullet {
	order?: string;
	active?: boolean;
	sx?: SxProps;
}

interface INumberBullet extends IBullet {
	order?: string;
}

const Bullet = ({ active, sx }: IBullet) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				borderRadius: '50%',
				width: 8,
				height: 8,
				borderColor: theme.palette.info.main,
				backgroundColor: active ? theme.palette.info.main : 'lightgray',
				...sx,
			}}
		/>
	);
};

const NumberBullet = ({ order, active, sx }: INumberBullet) => {
	const theme = useTheme();
	const bkColor = active ? theme.palette.info.light : 'lightgray';

	return (
		<Box
			sx={{
				borderRadius: '50%',
				width: theme.spacing(3),
				height: theme.spacing(3),
				borderColor: bkColor,
				backgroundColor: bkColor,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...sx,
			}}
		>
			<Typography variant='h6'>{order} </Typography>
		</Box>
	);
};

export { Bullet, NumberBullet };
