export const DRAWER_WIDTH = 60;
export const DRAWER_HOVER_WIDTH = 240;
export const MAX_BILL_CHARGE = 10000000;
export const LANGUAGES = ['zh-TW'];
export const SHIPPING_TYPE = {
	PALLET: 'pallet',
	BOX: 'box',
	VEHICLE: 'vehicle',
	CONTAINER: 'container',
	MULTISTOP: 'multistop',
	MULTISITE: 'multisite',
};
export const SHIPPING_TYPE_PATHS = {
	PALLET: '/pallet',
	CONTAINER: '/container',
	MULTISTOP: '/multistop',
	MULTISITE: '/multisite',
};
