import { useMemo, ReactNode } from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import * as yup from 'yup';
import {
	useForm,
	FormProvider as HookFormProvider,
	UseFormProps,
	UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormProviderProps {
	schema?: yup.AnyObjectSchema;
	defaultValues?: object;
	formProps?: UseFormProps;
	render?: (methods: UseFormReturn) => ReactNode;
	onSubmit?: (values: object) => void;
	children?: ReactNode;
	enableReinitialize?: boolean;
}

const FormProvider = ({
	schema,
	defaultValues,
	formProps = {},
	render,
	children,
	enableReinitialize,
	onSubmit = () => {},
}: FormProviderProps) => {
	const formOptions = useMemo<any>(() => {
		const opts = {
			mode: 'onChange',
			...formProps,
			defaultValues,
		};
		if (schema) {
			opts.resolver = yupResolver(schema);
		}
		return opts;
	}, [schema, defaultValues, ...Object.values(formProps)]);
	const methods = useForm(formOptions);

	useUpdateEffect(() => {
		if (!enableReinitialize) return;
		methods.reset(defaultValues);
	}, [defaultValues, enableReinitialize]);

	return (
		<HookFormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
				{render ? render(methods) : children}
			</form>
		</HookFormProvider>
	);
};

FormProvider.defaultProps = {
	formProps: {
		shouldUnregister: true,
	},
};

export default FormProvider;
