import { useState, useMemo, useCallback } from 'react';
import { get, useWatch, useFormContext } from 'react-hook-form';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { Wrapper } from '@googlemaps/react-wrapper';
import SearchInput from './SearchInput';

interface GoogleMapAutoCompleteProps {
	name: string;
	label: string;
	required: boolean;
	disabled?: boolean;
	language?: string;
	country?: string | string[] | null;
	onChange: (opt: ReturnOption | null) => void;
	prefix?: string;
	startAdornment?: string;
	placeholder?: string;
}
interface ReturnOption {
	label: string;
	address: string;
	lat?: number | null;
	lng?: number | null;
	postalCode?: string | undefined;
}
interface Option {
	label: string;
	value: string;
	data?: OptionData;
}
interface OptionData {
	placeId: string;
}

const inputChangeReasonEnum = {
	INPUT: 'input',
	RESET: 'reset',
	CLEAR: 'clear',
};

const closeReasonEnum = {
	CREATE_OPTION: 'createOption',
	TOGGLE_INPUT: 'toggleInput',
	ESCAPE: 'escape',
	SELECT_OPTION: 'selectOption',
	REMOVE_OPTION: 'removeOption',
	BLUR: 'blur',
};

const GoogleMapAutoComplete = ({
	country = 'tw',
	onChange,
	startAdornment,
	prefix = '',
	name,
	...props
}: GoogleMapAutoCompleteProps) => {
	const styles = useStyles();
	const { getValues } = useFormContext();
	const addressValue = useWatch({
		name,
	});
	const defaultValue = useMemo(() => {
		const values = getValues();
		return get(values, name) ?? '';
	}, [name]);

	const [inputValue, setInputValue] = useState(() =>
		getInputValue(prefix, defaultValue)
	);
	const [tempAddress, setTempAddress] = useState(defaultValue);

	const [options, setOptions] = useState<Option[]>(() => {
		if (inputValue) {
			return [
				{
					label: tempAddress,
					value: inputValue,
				},
			];
		}
		return [];
	});
	const clearData = useCallback(() => {
		onChange(null);
		setInputValue('');
		setTempAddress('');
		setOptions([]);
	}, [onChange]);
	const displaySuggestions = useCallback(
		(
			predictions: google.maps.places.QueryAutocompletePrediction[] | null,
			status: google.maps.places.PlacesServiceStatus
		) => {
			if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
				return;
			}
			if (prefix)
				predictions = predictions?.filter((option) =>
					option.description.includes(prefix)
				);
			setOptions(
				predictions.reduce<Option[]>((acc, { description, place_id }) => {
					if (place_id) {
						acc.push({
							label: description,
							value: getInputValue(prefix, description),
							data: {
								placeId: place_id,
							},
						});
					}
					return acc;
				}, [])
			);
		},
		[prefix]
	);
	const service = useMemo(
		() => new google.maps.places.AutocompleteService(),
		[]
	);
	const geocoder = useMemo(() => new google.maps.Geocoder(), []);
	const onInputChange = useCallback(
		(e, newInputValue, reason) => {
			if (reason === inputChangeReasonEnum.CLEAR) {
				clearData();
			} else if (reason === inputChangeReasonEnum.INPUT) {
				setInputValue(newInputValue);
			} else if (reason === inputChangeReasonEnum.RESET) {
				setInputValue(getInputValue(prefix, newInputValue));
			}
		},
		[prefix, service]
	);
	const onSelect = useCallback(
		async (opt) => {
			if (opt === null) {
				clearData();
				return;
			}
			const { label, data } = opt;
			try {
				const { results } = await geocoder.geocode({ placeId: data.placeId });
				const lat = results[0].geometry.location.lat();
				const lng = results[0].geometry.location.lng();
				const address = getAddress(results[0]);
				const isAdress =
					results[0].types.includes('street_address') ||
					results[0].types.includes('premise');
				const postalCode = results[0].address_components
					.find((comp) => comp.types.includes('postal_code'))
					?.short_name?.slice(0, 3);
				const newOpt: ReturnOption = {
					label: isAdress ? '' : label,
					address: address,
					lat,
					lng,
					postalCode,
				};
				const newInputValue = getInputValue(prefix, address);
				setTempAddress(address);
				setInputValue(newInputValue);
				setOptions([
					{
						label: address,
						value: newInputValue,
					},
				]);
				onChange(newOpt);
			} catch (e) {
				setInputValue('');
				setOptions([]);
			}
		},
		[onChange, prefix]
	);
	const onClose = useCallback(
		(e, reason) => {
			if (reason === closeReasonEnum.BLUR) {
				if (!inputValue || inputValue === getInputValue(prefix, tempAddress))
					return;
				clearData();
			}
		},
		[inputValue, tempAddress, options, prefix]
	);
	const onKeyPress = useCallback(
		(e) => {
			if (e.code === 'Enter') {
				e.preventDefault();
				e.stopPropagation();
				const opt = options.find((o) => o.label.indexOf(e.target.value) > -1);
				if (opt) {
					onSelect(opt);
				}
			}
		},
		[options, onSelect]
	);
	useUpdateEffect(() => {
		const newInputValue = getInputValue(prefix, addressValue);
		if (newInputValue !== inputValue) {
			setInputValue(addressValue);
		}
	}, [addressValue]);
	useUpdateEffect(() => {
		if (inputValue) {
			service.getPlacePredictions(
				{
					input: `${prefix}${inputValue}`,
					componentRestrictions: { country },
				},
				displaySuggestions
			);
		}
	}, [inputValue]);

	return (
		<SearchInput<OptionData>
			{...props}
			name={name}
			listboxSx={styles.listboxSx}
			freeSolo={true}
			inputValue={inputValue}
			options={options}
			onChange={onSelect}
			onInputChange={onInputChange}
			onClose={onClose}
			onKeyPress={onKeyPress}
			startAdornment={`${startAdornment ? startAdornment + ' ' : ''}${prefix}`}
		/>
	);
};

const withGoogleMapsAPI =
	(Comp: React.JSXElementConstructor<GoogleMapAutoCompleteProps>) =>
	({
		apiKey,
		language = 'zh-TW',
		...props
	}: GoogleMapAutoCompleteProps & { apiKey: string }) =>
		(
			<Wrapper apiKey={apiKey} libraries={['places']} language={language}>
				<Comp {...props} />
			</Wrapper>
		);

const useStyles = () => ({
	listboxSx: {
		'& .MuiAutocomplete-option': {
			fontFamily: 'PingFang TC',
			fontSize: '12px',
			margin: '0 8px',
			padding: '8px !important',
			borderRadius: '8px',
			fontWeight: 400,
			lineHeight: '17px',
			height: '33px',
			'&.Mui-focused': {
				bgcolor: 'rgb(245, 245, 245) !important',
			},
			'&.MuiAutocomplete-option[aria-selected="true"]': {
				bgcolor: 'color.divider',
			},
		},
	},
});

function getAddress(result: google.maps.GeocoderResult) {
	const postalCodeComponent = result.address_components.find((comp) =>
		comp.types.includes('postal_code')
	);
	let address = result.formatted_address;
	if (postalCodeComponent) {
		address = address.replace(postalCodeComponent.long_name, '').trim();
	}
	return address;
}

function getInputValue(prefix: string, value: string) {
	return prefix ? value.replace(prefix, '') : value;
}

export default withGoogleMapsAPI(GoogleMapAutoComplete);
