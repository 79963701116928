import { lazy } from 'react';

const DashboardAdminPage = lazy(
	() =>
		import('./DashboardAdminPage' /* webpackChunkName: 'AdminDashboardPage' */)
);

const DashboardAdminMultisitePage = lazy(
	() =>
		import(
			'./DashboardAdminPage/DashboardAdminMultisitePage' /* webpackChunkName: 'AdminDashboardPage' */
		)
);

const DashboardShipperPage = lazy(
	() =>
		import(
			'./DashboardShipperPage' /* webpackChunkName: 'DashboardShipperPage' */
		)
);

const DashboardCarrierPage = lazy(
	() =>
		import(
			'./DashboardCarrierPage' /* webpackChunkName: 'DashboardCarrierPage' */
		)
);

export {
	DashboardAdminPage,
	DashboardAdminMultisitePage,
	DashboardShipperPage,
	DashboardCarrierPage,
};
