import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';

interface TabsProps extends MuiTabsProps {}

const Tabs = (props: TabsProps) => {
	const styles = useStyles();
	return <MuiTabs {...props} sx={styles.Tabs} />;
};

interface TabProps extends MuiTabProps {
	iconComponent?: React.ReactNode;
}
const Tab = ({ iconComponent, ...otherProps }: TabProps) => {
	const styles = useStyles((otherProps as any).selected);
	return (
		<Box sx={styles.TabBox}>
			{iconComponent}
			<MuiTab disableRipple {...otherProps} sx={styles.Tab} />
		</Box>
	);
};

const useStyles = (selected?: boolean) => {
	return {
		Tabs: {
			'& .MuiTabs-indicator': {
				display: 'flex',
				justifyContent: 'center',
				backgroundColor: 'transparent',
			},
			'& .MuiTabs-indicatorSpan': {
				justifyContent: 'center',
				backgroundColor: 'color.black',
				display: 'block',
				height: '4px',
				borderRadius: '8px',
			},
		},
		TabBox: {
			display: 'flex',
			position: 'relative',
			px: '5px',
			flex: 1,
			'& .MuiSvgIcon-root': {
				position: 'absolute',
				margin: 'auto',
				top: 0,
				bottom: 0,
			},
		},
		Tab: {
			maxWidth: 'none',
			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: 0,
				width: '100%',
				height: '4px',
				display: 'block',
				background: selected ? '#231916' : alpha('#231916', 0.08),
				borderRadius: '8px',
			},
			flex: 1,
			m: 0,
			alignItems: 'center',
		},
	};
};

export { Tab, Tabs };
