import { lazy } from 'react';

const SsoPage = lazy(
	() => import('./SsoPage' /* webpackChunkName: 'SsoPaths' */)
);

const SsoBindingPage = lazy(
	() => import('./Binding/SsoBindingPage' /* webpackChunkName: 'SsoPaths' */)
);

export { SsoPage, SsoBindingPage };
