import MuiStepper, {
	StepperProps as MuiStepperProps,
} from '@mui/material/Stepper';
import Box from '@mui/material/Box';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import Step from '@mui/material/Step';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import StepLabel from './StepLabel';

interface StepProps {
	label: string;
	active: boolean;
	address?: string;
	location?: string;
	arrivalTime?: number;
}
interface Stepper extends MuiStepperProps {
	steps: StepProps[];
	type?: string;
}

const Process = ({ steps, type, children, ...props }: Stepper) => {
	const theme = useTheme();

	return (
		<Box sx={{ maxWidth: '500px' }}>
			<MuiStepper
				orientation='vertical'
				connector={
					<StepConnector
						sx={{
							marginLeft: '8px',
							[`& .${stepConnectorClasses.line}`]: {
								diaplay: 'flex',
								width: '4px',
								border: 0,
								backgroundColor: theme.palette.grey,
								borderRadius: 1,
							},
							[`&.${stepConnectorClasses.active}`]: {
								[`& .${stepConnectorClasses.line}`]: {
									backgroundColor: theme.palette.info.main,
									opacity: 0.3,
								},
							},
						}}
					/>
				}
				{...props}
			>
				{steps.map((step) => (
					<Step key={step.label} active>
						<StepLabel active={step.active}>{step.label}</StepLabel>
						<Divider sx={{ my: 2, ml: 4 }} />
					</Step>
				))}
			</MuiStepper>
		</Box>
	);
};

Process.defaultProps = {
	orientation: 'vertical',
	type: 'dot',
};

export default Process;
