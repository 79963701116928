import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { Grid, Paper, Box } from '@mui/material';
import Title from './Title';
import type { TypographyTypeMap } from '@mui/material/Typography';

type SectionProps = {
	sx?: SxProps<Theme>;
	title?: string | ReactNode;
	variant?: TypographyTypeMap['props']['variant'];
	titleSx?: SxProps;
	children?: ReactNode;
	rightTitleElement?: ReactNode;
	fullHeight?: boolean;
};

const Section = ({
	sx,
	title,
	variant,
	titleSx,
	rightTitleElement,
	children,
	fullHeight = false,
}: SectionProps) => {
	return (
		<Grid item xs={12} height={fullHeight ? '100%' : undefined}>
			<Paper
				elevation={0}
				sx={{
					p: 2,
					display: 'flex',
					flexDirection: 'column',
					height: fullHeight ? '100%' : undefined,
					...sx,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						pb: 2,
						flexFlow: 'row wrap',
					}}
				>
					<Title variant={variant} titleSx={titleSx}>
						{title}
					</Title>
					{rightTitleElement}
				</Box>
				{children}
			</Paper>
		</Grid>
	);
};

export default Section;
