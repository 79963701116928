import React, { useMemo } from 'react';
import store from 'store2';
import { SWRConfiguration, SWRConfig } from 'swr';
import ajax from '~/libraries/ajax';
import useEnqueueSnackbar from '~/hooks/useEnqueueSnackbar';
import { useTranslation } from 'react-i18next';
import { REFRESH_TOKEN_EXPIRED } from '~/enums/errorMessages';

interface Configuration extends Omit<SWRConfiguration, 'provider'> {
	provider?: () => any;
}

const initialState = new Map([['isLogin', store.has('token')]]);

const SWRConfigProvider = ({ children }: { children: React.ReactNode }) => {
	const pushSnackbar = useEnqueueSnackbar();
	const { t } = useTranslation();
	const config: Configuration = useMemo(() => {
		return {
			errorRetryCount: 0,
			revalidateOnFocus: false,
			provider: () => initialState,
			fetcher: (resource) => ajax(resource).then((res) => res.data),
			onError: (err, key, config) => {
				if (err?.message === REFRESH_TOKEN_EXPIRED) {
					const { mutate } = config as any;
					pushSnackbar(t('ajax:refreshTokenExpired'), {
						variant: 'warning',
					});
					mutate('isLogin', false, false);
					store.remove('token');
					store.remove('refreshToken');
				}
				return err;
			},
		};
	}, [t, pushSnackbar]);
	return <SWRConfig value={config}>{children}</SWRConfig>;
};

export default SWRConfigProvider;
