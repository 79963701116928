import MuiStepLabel, {
	StepLabelProps as MuiStepLabelProps,
} from '@mui/material/StepLabel';
import Stack from '@mui/material/Stack';
import { Bullet } from './Bullet';

interface StepLabelProps extends MuiStepLabelProps {
	active?: boolean;
}

const StepLabel = ({ active, icon, children, ...props }: StepLabelProps) => (
	<MuiStepLabel sx={{ padding: 0 }} {...props}>
		<Stack direction='row' alignItems='center'>
			<Bullet
				active={active}
				sx={{ width: 20, height: 20, position: 'relative', left: '10px' }}
			/>
			{children}
		</Stack>
	</MuiStepLabel>
);

export default StepLabel;
