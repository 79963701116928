import { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
import FormLabel from './FormLabel';
import TextField from './TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiTimePicker, {
	TimePickerProps as MuiTimePickerProps,
} from '@mui/lab/TimePicker';

interface TimePickerProps
	extends Omit<MuiTimePickerProps, 'onChange' | 'renderInput' | 'value'> {
	label?: string;
	tooltip?: string;
	name: string;
	required?: boolean;
	onChange?: Function;
	placeholder?: string;
}

const TimePicker = ({
	label,
	tooltip,
	name,
	required,
	onChange,
	placeholder,
	disableOpenPicker,
	...otherProps
}: TimePickerProps) => {
	const { control } = useFormContext();
	const anchorEl = useRef<any>(null);
	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { onChange: onFieldChange, value = '' },
				fieldState: { error },
			}) => {
				const errorMsg = error?.message;
				const isError = !!errorMsg;
				return (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<FormControl
							component='fieldset'
							error={isError}
							ref={anchorEl}
							sx={{ width: '100%' }}
						>
							<MuiTimePicker
								disableOpenPicker
								label={
									<FormLabel
										required={required}
										label={label}
										tooltip={tooltip}
									/>
								}
								value={value}
								onChange={(value) => {
									const result = value?.getTime() ?? null;
									onChange ? onChange(result) : onFieldChange(result);
								}}
								renderInput={(params) => (
									<TextField {...params} variant='standard' />
								)}
								PopperProps={{
									anchorEl: () => anchorEl?.current,
								}}
								{...otherProps}
							/>
							<FormHelperText>{errorMsg}</FormHelperText>
						</FormControl>
					</LocalizationProvider>
				);
			}}
		/>
	);
};

export default TimePicker;
