import { alpha, createTheme } from '@mui/material/styles';
import { indigo, pink, red, orange, green, common } from '@mui/material/colors';
import {
	BLACK,
	ERROR,
	WARNING,
	BACKGROUND,
	DIVIDER,
	SUCCESS,
	WHITE,
	BLUE,
	TAG_BLUE,
	ALLY_BLUE,
	BACKGROUND_BLUE,
	BACKGROUND_GRAY,
	BACKGROUND_BLACK,
	BACKGROUND_WHITE,
} from './colors';
import MuiButton from './components/MuiButton';
import MuiTextField from './components/MuiTextField';
import MuiInputBase from './components/MuiInputBase';
import MuiInputLabel from './components/MuiInputLabel';
import MuiFormHelperText from './components/MuiFormHelperText';
import MuiTypography from './components/MuiTypography';
import MuiFormControl from './components/MuiFormControl';
import {
	MuiTableRow,
	MuiTableHead,
	MuiTable,
} from './components/MuiTableComponents';
import MuiTablePagination from './components/MuiTablePagination';

const defaultTheme = createTheme({
	palette: {
		primary: {
			light: alpha(BLACK, 0.6),
			main: BLACK,
			dark: indigo[700],
		},
		secondary: {
			light: pink.A200,
			main: pink.A400,
			dark: pink.A700,
		},
		error: {
			light: red[300],
			main: ERROR,
			dark: red[700],
		},
		warning: {
			light: orange[300],
			main: orange[500],
			dark: orange[700],
		},
		info: {
			light: '#6d8dff',
			main: '#2454FF',
			dark: '#0033e9',
		},
		success: {
			light: green[300],
			main: green[500],
			dark: green[700],
		},
		text: {
			primary: BLACK,
			secondary: 'rgba(0, 0, 0, 0.54)',
			gray: alpha(BLACK, 0.6),
			white: '#fff',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)',
		},
		divider: DIVIDER,
		background: {
			paper: common.white,
			default: '#fff',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0.04)',
			hoverOpacity: 0.04,
			selected: 'rgba(0, 0, 0, 0.08)',
			selectedOpacity: 0.08,
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
			disabledOpacity: 0.38,
			focus: 'rgba(0, 0, 0, 0.12)',
			focusOpacity: 0.12,
			activatedOpacity: 0.12,
		},
		color: {
			white: WHITE,
			white10: alpha(WHITE, 0.1),
			white20: alpha(WHITE, 0.2),
			black: BLACK,
			black80: '#4F4745',
			black60: '#7B7573',
			black50: '#918C8A',
			black40: '#A7A3A2',
			black20: '#BEBCBB',
			black10: alpha(BLACK, 0.1),
			black8: '#EDEDEC',
			gray: '',
			success: SUCCESS,
			success60: alpha(SUCCESS, 0.6),
			success10: alpha(SUCCESS, 0.1),
			warning: WARNING,
			warning30: alpha(WARNING, 0.3),
			error: ERROR,
			error60: alpha(ERROR, 0.6),
			error10: alpha(ERROR, 0.1),
			info: BLACK,
			divider: DIVIDER,
			background: BACKGROUND,
			bgBlack: BACKGROUND_BLACK,
			bgBlue: BACKGROUND_BLUE,
			bgGray: BACKGROUND_GRAY,
			blue: BLUE,
			blue60: alpha(BLUE, 0.6),
			blue10: alpha(BLUE, 0.1),
			tagBlue: TAG_BLUE,
			allyBlue: ALLY_BLUE,
			allyBlue60: alpha(ALLY_BLUE, 0.6),
			allyBlue10: alpha(ALLY_BLUE, 0.1),
			bgWhite: BACKGROUND_WHITE,
		},
	},
	zIndex: {
		mobileStepper: 1000,
		speedDial: 1050,
		appBar: 1100,
		drawer: 1200,
		modal: 1300,
		snackbar: 1400,
		tooltip: 1500,
	},
	spacing: 8,
	typography: {},
	components: {
		MuiButton,
		MuiTextField,
		MuiInputBase,
		MuiInputLabel,
		MuiFormHelperText,
		MuiTypography,
		MuiTableRow,
		MuiTableHead,
		MuiTable,
		MuiTablePagination,
		MuiFormControl,
	},
});

// custom themes
defaultTheme.modalPaper = {
	position: 'absolute',
	top: `50%`,
	left: `50%`,
	transform: `translate(-50%, -50%)`,
	width: 400,
	backgroundColor: common.white,
	border: '2px solid #000',
	boxShadow: defaultTheme.shadows[5],
	padding: defaultTheme.spacing(2, 4, 3),
};

export default defaultTheme;
