import { lazy } from 'react';

const MultistopAdminPage = lazy(
	() =>
		import('./MultistopAdminPage' /* webpackChunkName: 'MultistopAdminPage' */)
);

const MultistopShipperPage = lazy(
	() =>
		import(
			'./MultistopShipperPage' /* webpackChunkName: 'MultistopShipperPage' */
		)
);

const MultistopCarrierPage = lazy(
	() =>
		import(
			'./MultistopCarrierPage' /* webpackChunkName: 'MultistopCarrierPage' */
		)
);

const MultistopShippingOrderPage = lazy(
	() =>
		import(
			'./ShippingOrder/MultistopShippingOrderPage' /* webpackChunkName: 'MultistopShippingOrderPage' */
		)
);

const MultistopShippingOrderDetailPage = lazy(
	() =>
		import(
			'./ShippingOrderDetail/MultistopShippingOrderDetailPage' /* webpackChunkName: 'MultistopShippingOrderDetailPage' */
		)
);

const MultistopShipmentDetailPage = lazy(
	() =>
		import(
			'./ShipmentDetail/MultistopShipmentDetailPage' /* webpackChunkName: 'MultistopShippingOrderDetailPage' */
		)
);

export {
	MultistopAdminPage,
	MultistopShipperPage,
	MultistopCarrierPage,
	MultistopShippingOrderPage,
	MultistopShippingOrderDetailPage,
	MultistopShipmentDetailPage,
};
