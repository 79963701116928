import clsx from 'clsx';
import { BaseTextFieldProps } from '@mui/material/TextField';
import { Box, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputField from './InputField';
import { useFormContext, Controller } from 'react-hook-form';

interface SearchTextFieldProps
	extends Omit<
		BaseTextFieldProps,
		'name' | 'label' | 'onChange' | 'InputProps' | 'inputProps'
	> {
	name: string;
	label: string;
	styleSize?: string;
	onChange?: Function;
}

const SearchTextField = ({
	name,
	label,
	styleSize,
	onChange,
}: SearchTextFieldProps) => {
	const { control, setValue } = useFormContext();
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange: onFieldDataChange, value } }) => {
				const onFieldChange = onChange ? onChange : onFieldDataChange;
				return (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<InputField
							className={clsx([
								styleSize && `Customized-${styleSize}`,
								'Customized-search',
							])}
							label={label}
							name={name}
							value={value}
							onChange={(e: any) => onFieldChange(e)}
							onBlur={(e) => {
								const value = e.target.value;
								const trimValue = value.trim();
								if (trimValue !== value) {
									setValue(name, trimValue);
								}
							}}
							endAdornment={
								value ? (
									<IconButton
										aria-label='delete'
										size='small'
										sx={{ marginRight: '-6px' }}
										onClick={() => onFieldChange('')}
									>
										<ClearIcon sx={{ color: 'color.black' }} />
									</IconButton>
								) : (
									<></>
								)
							}
						/>
						<Button
							sx={{
								bgcolor: '#D9D9D9',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '40px',
								borderRadius: '0px 8px 8px 0px',
								cursor: 'pointer',
								minWidth: '40px',
								'&:hover': {
									bgcolor: 'color.black20',
								},
							}}
							type='submit'
						>
							<SearchIcon />
						</Button>
					</Box>
				);
			}}
		/>
	);
};

export default SearchTextField;
