import MuiTooltip, {
	TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const Tooltip = ({ title, children, ...props }: MuiTooltipProps) => {
	return (
		<MuiTooltip title={title} TransitionComponent={Zoom} {...props}>
			{children}
		</MuiTooltip>
	);
};

Tooltip.defaultProps = {
	arrow: true,
	placement: 'top',
};

export default Tooltip;
