import { lazy } from 'react';

const MultisiteShipperPage = lazy(
	() =>
		import(
			'./MultisiteShipperPage' /* webpackChunkName: 'MultisiteShipperPage' */
		)
);

const MultisiteShipmentAdminPage = lazy(
	() =>
		import(
			'./Shipment/ShipmentAdminPage' /* webpackChunkName: 'MultisiteShipmentAdminPage' */
		)
);

const MultisiteShippingOrderAdminPage = lazy(
	() =>
		import(
			'./ShippingOrder/ShippingOrderAdminPage' /* webpackChunkName: 'MultisiteShippingOrderAdminPage' */
		)
);

const DispatchPage = lazy(
	() => import('./Dispatch/DispatchPage' /* webpackChunkName: 'DispatchPage' */)
);

const MultisiteShippingOrderPage = lazy(
	() =>
		import(
			'./ShippingOrder/MultisiteShippingOrderPage' /* webpackChunkName: 'MultisiteShippingOrderPage' */
		)
);

const CreateMultisiteShippingOrderPage = lazy(
	() =>
		import(
			'./ShippingOrder/Create/CreateMultisiteShippingOrderPage' /* webpackChunkName: 'MultisiteShippingOrderPage' */
		)
);

const MultisiteShippingOrderDetailPage = lazy(
	() =>
		import(
			'./ShippingOrderDetail/MultisiteShippingOrderDetailPage' /* webpackChunkName: 'MultisiteShippingOrderDetailPage' */
		)
);

const MultisiteShipmentDetailPage = lazy(
	() =>
		import(
			'./ShipmentDetail/MultisiteShipmentDetailPage' /* webpackChunkName: 'MultisiteShippingOrderDetailPage' */
		)
);

export {
	DispatchPage,
	MultisiteShipperPage,
	MultisiteShipmentAdminPage,
	MultisiteShippingOrderAdminPage,
	MultisiteShippingOrderPage,
	CreateMultisiteShippingOrderPage,
	MultisiteShippingOrderDetailPage,
	MultisiteShipmentDetailPage,
};
