import { lazy } from 'react';

const ContractListShipperPage = lazy(
	() =>
		import(
			'./Contract/ContractListShipperPage' /* webpackChunkName: 'ContractListShipperPage' */
		)
);

const ContractDetailShipperPage = lazy(
	() =>
		import(
			'./Contract/Detail/ContractDetailShipperPage' /* webpackChunkName: 'ContractDetailShipperPage' */
		)
);

const PendingBillPage = lazy(
	() =>
		import(
			'./PendingBill/PendingBillPage' /* webpackChunkName: 'PendingBillPage' */
		)
);

const BillsAdminPage = lazy(
	() =>
		import('./Bills/BillsAdminPage' /* webpackChunkName: 'BillsAdminPage' */)
);

const BillsShipperPage = lazy(
	() =>
		import(
			'./Bills/BillsShipperPage' /* webpackChunkName: 'BillsShipperPage' */
		)
);

const BillPreviewPage = lazy(
	() =>
		import('./Bills/BillPreviewPage' /* webpackChunkName: 'BillPreviewPage' */)
);

export {
	ContractListShipperPage,
	ContractDetailShipperPage,
	PendingBillPage,
	BillsAdminPage,
	BillsShipperPage,
	BillPreviewPage,
};
