import { Backdrop, Box, Fade, IconButton } from '@mui/material';
import Modal, { ModalProps } from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

interface ModalViewerProps extends Omit<ModalProps, 'onClose' | 'children'> {
	src?: string;
	onClose: Function;
	children?: ReactNode;
	fullScreen?: boolean;
}

const ModalViewer = ({
	src,
	open,
	onClose,
	children,
	fullScreen,
}: ModalViewerProps) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	return (
		<Modal
			open={open}
			onClose={() => {
				onClose();
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				sx: {
					bgcolor: 'rgba(35, 25, 22, 0.4)',
				},
			}}
		>
			<Fade in={open}>
				<Box
					sx={[
						styles.container,
						fullScreen ? { width: '80vw', height: '80vh' } : {},
					]}
				>
					<IconButton
						aria-label='Delete'
						size='small'
						sx={styles.close}
						onClick={() => {
							onClose();
						}}
					>
						<ClearIcon />
					</IconButton>
					{children ? (
						children
					) : (
						<img src={src} alt='modal' style={styles.img} />
					)}
				</Box>
			</Fade>
		</Modal>
	);
};

const useStyles = (theme: any): any => {
	return {
		container: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			maxHeight: '80vh',
			borderRadius: '10px',
			overflow: 'auto',
			outline: 'none',
			fontSize: 0,
			backgroundColor: 'white',
		},
		img: {
			maxWidth: '80vw',
			border: 0,
			outline: 'none',
			objectFit: 'contain',
		},
		close: {
			position: 'absolute',
			top: '8px',
			right: '8px',
			color: 'white',
			bgcolor: 'color.black',
			padding: 0,
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
	};
};

export default ModalViewer;
