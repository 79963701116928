import { lazy } from 'react';

const CarriersPage = lazy(
	() => import('./CarriersPage' /* webpackChunkName: 'CarriersPage' */)
);

const CarrierDetailPage = lazy(
	() =>
		import('./CarrierDetailPage' /* webpackChunkName: 'CarrierDetailPage' */)
);

const CarrierContractPage = lazy(
	() =>
		import(
			'./Contract/CarrierContractPage' /* webpackChunkName: 'CarrierContractPage' */
		)
);

export { CarriersPage, CarrierDetailPage, CarrierContractPage };
