import clsx from 'clsx';
import { useState } from 'react';
import type { ReactElement } from 'react';
import type { KeyboardEventHandler } from 'react';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import type { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useFormContext, Controller } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormLabel from './FormLabel';
import TextField from './TextField';

export interface Option<T = any> {
	label: string;
	value: string;
	data?: T;
}

interface SearchInputProps<T> {
	name: string;
	label: string;
	required: boolean;
	styleSize?: string;
	disabled?: boolean;
	freeSolo?: boolean;
	disablePortal?: boolean;
	noOptionsText?: string;
	options: Option<T>[];
	placeholder?: string;
	onChange?: Function;
	defaultValue?: Option;
	renderOption?: any;
	inputValue?: string;
	groupBy?: Function;
	getOptionDisabled?: Function;
	onInputChange?: (
		e: any,
		newInputValue: any,
		reason: AutocompleteInputChangeReason
	) => void;
	onClose?: (event: object, reason: string) => void;
	onKeyPress?: KeyboardEventHandler<T> | undefined;
	listboxSx?: SxProps;
	filterOptions?: any;
	startAdornment?: string;
	sx?: SxProps;
	containerSx?: SxProps;
	loading?: boolean;
	loadingText?: ReactElement;
}

const SearchInput = <T,>({
	name,
	label = '',
	required,
	options,
	placeholder,
	onChange,
	defaultValue,
	listboxSx,
	styleSize,
	freeSolo,
	startAdornment,
	sx,
	containerSx,
	...props
}: SearchInputProps<T>) => {
	const [autocompleteKey, setAutocompleteKey] = useState<number | null>(null);
	const { control } = useFormContext();
	const { t } = useTranslation();

	useUpdateEffect(() => {
		if (!freeSolo) {
			// set defaultValue from dynamic options
			setAutocompleteKey(new Date().getTime());
		}
	}, [JSON.stringify(options)]);
	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { value = '', onChange: onFieldChange },
				fieldState: { error },
			}) => {
				const errorMsg = error?.message;
				const isError = !!errorMsg;
				const option = freeSolo
					? value
					: options.find((o) => o.value === value);
				return (
					<Autocomplete
						key={autocompleteKey}
						value={option}
						freeSolo={freeSolo}
						autoSelect={freeSolo}
						isOptionEqualToValue={(option, value) =>
							option.value == value?.value
						}
						// @ts-ignore-start
						ListboxProps={{ sx: listboxSx }}
						// @ts-ignore-end
						defaultValue={defaultValue}
						options={options}
						getOptionLabel={(option: any) => option?.label ?? option}
						popupIcon={<KeyboardArrowDownIcon />}
						noOptionsText={t('noOptions')}
						sx={containerSx}
						renderInput={(params: any) => {
							return (
								<TextField
									{...params}
									label={<FormLabel required={required} label={label} />}
									name={name}
									error={isError}
									helperText={errorMsg}
									placeholder={placeholder}
									className={clsx(styleSize && `Customized-${styleSize}`)}
									variant='standard'
									InputProps={{
										...params.InputProps,
										startAdornment: startAdornment && (
											<InputAdornment position='start'>
												{startAdornment}
											</InputAdornment>
										),
									}}
									sx={sx}
								/>
							);
						}}
						onChange={(e, v) => {
							const val = typeof v === 'string' ? v : v?.value;
							onChange ? onChange(v) : onFieldChange(val);
						}}
						{...props}
					/>
				);
			}}
		/>
	);
};

SearchInput.defaultProps = {
	disabled: false,
	required: false,
	freeSolo: false,
	disablePortal: false,
};

export default SearchInput;
