import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';

const Chip = ({ children, sx, ...props }: MuiChipProps) => {
	return (
		<MuiChip sx={{ textTransform: 'none', color: 'white', ...sx }} {...props} />
	);
};

Chip.defaultProps = {
	size: 'small',
	color: 'primary',
};

export default Chip;
