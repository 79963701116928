import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

const Snackbar = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
	const { severity, children } = props;
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant='filled'
			severity={severity}
			sx={{
				color: 'color.white',
				bgcolor: severity === 'error' ? 'color.error' : 'color.black',
				ml: '60px',
				width: '300px',
				py: '10px',
				borderRadius: '8px',
			}}
			iconMapping={{
				success: <CheckCircleIcon fontSize='inherit' />,
				error: <ErrorIcon fontSize='inherit' />,
				info: <InfoIcon fontSize='inherit' />,
				warning: <WarningIcon fontSize='inherit' />,
			}}>
			{children}
		</MuiAlert>
	);
});

export default Snackbar;
