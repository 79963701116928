import { ReactElement } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useSWRState from '~/hooks/useSWRState';

interface RequireAuthProps {
	children: ReactElement;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
	const location = useLocation();
	const { data: isLogin } = useSWRState('isLogin');

	if (!isLogin) {
		return <Navigate to='/login' state={{ from: location }} />;
	}
	return children;
};

export default RequireAuth;
