import type { Components } from '@mui/material/styles/components';
import { BACKGROUND } from '~/theme/colors';

export const MuiTable: Components['MuiTable'] = {
	styleOverrides: {
		root: {
			borderCollapse: 'separate',
			borderSpacing: '0 8px',
		},
	},
};

export const MuiTableHead: Components['MuiTableHead'] = {
	styleOverrides: {
		root: {
			'& .MuiTableCell-root': {
				borderWidth: 0,
				padding: '0px 16px',
			},
			'& .MuiTableRow-root': {
				backgroundColor: 'transparent',
			},
		},
	},
};

export const MuiTableRow: Components['MuiTableRow'] = {
	styleOverrides: {
		root: {
			borderSpacing: '4px',
			backgroundColor: BACKGROUND,
			'& .MuiTableCell-root': {
				borderWidth: 0,
			},
			'& .MuiTableCell-root: first-of-type': {
				borderTopLeftRadius: '8px',
				borderBottomLeftRadius: '8px',
			},
			'& .MuiTableCell-root: last-of-type': {
				borderTopRightRadius: '8px',
				borderBottomRightRadius: '8px',
			},
		},
	},
};

// export default { MuiTableHead, MuiTableRow, MuiTable };
