import * as Sentry from '@sentry/react';
import { systemType, SYSTEM_TYPE_ENUM } from '~/utils/urls';

const DSN_ENUM = {
	[SYSTEM_TYPE_ENUM.ADMIN]:
		'https://4c93d7537d32461986117127a9809105@o1323261.ingest.sentry.io/6580891',
	[SYSTEM_TYPE_ENUM.SHIPPER]:
		'https://8d262d9209a74205bfa7b28e1f3731ce@o1323261.ingest.sentry.io/6580885',
	[SYSTEM_TYPE_ENUM.CARRIER]:
		'https://5f70e2252d81499385ad5bc06bf352b8@o1323261.ingest.sentry.io/6580887',
};

const dsn = DSN_ENUM[systemType];

if (dsn) {
	Sentry.init({ dsn, environment: process.env.ENV_TYPE || 'local' });
}
