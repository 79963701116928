import { lazy } from 'react';

const PalletAdminPage = lazy(
	() => import('./PalletAdminPage' /* webpackChunkName: 'PalletAdminPage' */)
);

const PalletShipperPage = lazy(
	() =>
		import('./PalletShipperPage' /* webpackChunkName: 'PalletShipperPage' */)
);

const PalletCarrierPage = lazy(
	() =>
		import('./PalletCarrierPage' /* webpackChunkName: 'PalletCarrierPage' */)
);

const PalletShipperShippingOrderPage = lazy(
	() =>
		import(
			'./ShippingOrder/PalletShipperShippingOrderPage' /* webpackChunkName: 'PalletShipperShippingOrderPage' */
		)
);

const PalletAdminShippingOrderPage = lazy(
	() =>
		import(
			'./ShippingOrder/PalletAdminShippingOrderPage' /* webpackChunkName: 'PalletAdminShippingOrderPage' */
		)
);

const PalletShippingOrderDetailPage = lazy(
	() =>
		import(
			'./ShippingOrderDetail/PalletShippingOrderDetailPage' /* webpackChunkName: 'PalletShippingOrderDetailPage' */
		)
);

const PalletShipmentDetailPage = lazy(
	() =>
		import(
			'./ShipmentDetail/PalletShipmentDetailPage' /* webpackChunkName: 'PalletShippingOrderDetailPage' */
		)
);

export {
	PalletAdminPage,
	PalletShipperPage,
	PalletCarrierPage,
	PalletShipperShippingOrderPage,
	PalletAdminShippingOrderPage,
	PalletShippingOrderDetailPage,
	PalletShipmentDetailPage,
};
