import { alpha } from '@mui/material/styles';
import type { Components } from '@mui/material/styles/components';
import { BLACK } from '~/theme/colors';

const MuiInputBase: Components['MuiInputBase'] = {
	styleOverrides: {
		root: {
			color: BLACK,
			'& fieldset': {
				borderRadius: 8,
				borderColor: alpha(BLACK, 0.08),
			},
			'&.Mui-focused fieldset': {
				borderWidth: '1px !important',
			},
			'& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
				right: '8px',
			},
		},
	},
};

export default MuiInputBase;
