import {
	TableHead as MuiTableHead,
	TableHeadProps as MuiTableHeadProps,
} from '@mui/material';
import { TableCell, TableRow, Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface TableHeadProps extends MuiTableHeadProps {
	headerGroups: any;
}
const TableHead = ({ headerGroups }: TableHeadProps) => {
	return (
		<MuiTableHead>
			{headerGroups.map((headerGroup: any) => (
				<TableRow {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map((column: any) => {
						const { onClick, otherProps } = column.getSortByToggleProps();
						let toggleProps = {
							...otherProps,
							onClick: column.sortable ? onClick : () => {},
							style: column.sortable ? { cursor: 'pointer' } : {},
						};
						return (
							<TableCell
								{...column.getHeaderProps(toggleProps)}
								sx={{ wordBreak: 'keep-all' }}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'flex-start',
									}}
								>
									{column.render('Header')}
									{column.isSorted ? (
										column.isSortedDesc ? (
											<ArrowDropDownIcon />
										) : (
											<ArrowDropUpIcon />
										)
									) : (
										''
									)}
								</Box>
							</TableCell>
						);
					})}
				</TableRow>
			))}
		</MuiTableHead>
	);
};

export default TableHead;
