import { ReactNode } from 'react';
import Link, { LinkProps } from '@mui/material/Link';

interface LinkButtonProps extends LinkProps {
	text: string | ReactNode;
}

const LinkButton = ({
	text,
	sx,
	underline = 'none',
	target,
	rel,
	...props
}: LinkButtonProps) => {
	if (target === '_blank') {
		rel = 'noopener';
	}
	return (
		<Link
			underline={underline}
			target={target}
			rel={rel}
			sx={{
				wordBreak: 'keep-all',
				cursor: 'pointer',
				'&:hover': {
					opacity: 0.6,
				},
				'&:disabled': {
					opacity: 0.2,
					cursor: 'not-allowed',
				},
				...sx,
			}}
			{...props}
		>
			{text}
		</Link>
	);
};
export default LinkButton;
