import { CSSProperties } from 'react';
import { SystemStyleObject } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '../Button';

export interface TableEmptyViewProps {
	title?: string;
	description?: string;
	buttonText?: string;
	onClick?: Function;
	sx?: SystemStyleObject;
}

const TableEmptyView = ({
	title,
	description,
	buttonText,
	onClick,
	sx,
}: TableEmptyViewProps) => {
	const styles = useStyle();
	return (
		<Box sx={[styles.container, !!sx && sx]}>
			{title && <Typography variant='h5'>{title}</Typography>}

			{description && (
				<Typography variant={title ? 'body2' : 'body1'} sx={styles.description}>
					{description}
				</Typography>
			)}
			{buttonText && onClick && (
				<Button onClick={onClick} sx={styles.button}>
					{buttonText}
				</Button>
			)}
		</Box>
	);
};

const useStyle = () => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		p: 2,
		borderRadius: '8px',
		bgcolor: 'color.background',
		height: '100%',
	} as CSSProperties,
	description: {
		mt: 1,
	} as CSSProperties,
	button: {
		minWidth: '200px',
		mt: 3,
	} as CSSProperties,
});

export default TableEmptyView;
