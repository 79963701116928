import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { SxProps } from '@mui/system';
import MuiTable from '@mui/material/Table';
import { TableRow } from '@mui/material';
import MuiTableBody from '@mui/material/TableBody';
import {
	useTable,
	UseTableOptions,
	useSortBy,
	UseSortByState,
} from 'react-table';
import type { Column } from 'react-table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from './TableHead';
import Pagination from './Pagination';
import TableEmptyView from './TableEmptyView';
import type { TableEmptyViewProps } from './TableEmptyView';

export interface TableWrapperProps<Data extends object, S, Q> {
	data?: Data[];
	searchData?: S;
	emptyProps?: TableEmptyViewProps;
	columns: ReadonlyArray<Column<Data>>;
	hiddenHeaders?: boolean;
	tableSx?: SxProps;
	manualSortBy?: boolean;
	renderRowCells: Function;
	onQuery?: Q;
	onSort?: Function;
	onRowClick?: Function;
}

const TableWrapper = <Data extends object, S = void, Q = void>({
	data,
	emptyProps,
	searchData,
	onQuery,
	...props
}: TableWrapperProps<Data, S, Q>) => {
	if (!data) return null;
	if (!data.length) {
		return <TableEmptyView {...emptyProps} />;
	}
	return (
		<>
			<Table<Data> {...props} data={data} />
			{!!searchData && <Pagination onQuery={onQuery} searchData={searchData} />}
		</>
	);
};

interface TableProps<Data extends object> extends UseTableOptions<Data> {
	hiddenHeaders?: boolean;
	tableSx?: SxProps;
	renderRowCells: Function;
	onSort?: Function;
	onRowClick?: Function;
	manualSortBy?: boolean;
}

const Table = <Data extends object>({
	columns,
	data,
	tableSx,
	renderRowCells,
	hiddenHeaders,
	onSort,
	onRowClick,
	manualSortBy = true,
}: TableProps<Data>) => {
	const { getTableProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			// @ts-ignore-start
			manualSortBy,
			// @ts-ignore-end
		},
		useSortBy
	);

	useUpdateEffect(() => {
		const { sortBy } = state as UseSortByState<object>;
		if (sortBy && onSort) {
			onSort && onSort(sortBy);
		}
	}, [(state as UseSortByState<object>).sortBy, onSort]);

	const onClick = (v: any) => () => {
		if (onRowClick) onRowClick(v);
	};

	return (
		<TableContainer>
			<MuiTable {...getTableProps()} sx={tableSx}>
				{!hiddenHeaders && <TableHead headerGroups={headerGroups} />}
				<MuiTableBody
					sx={{
						'& .MuiTableRow-root': {
							cursor: onRowClick ? 'pointer' : 'auto',
						},
					}}
				>
					{rows.map((row, i: number) => {
						prepareRow(row);
						const v = row.original;
						return (
							<TableRow hover onClick={onClick(v)} {...row.getRowProps()}>
								{renderRowCells(v, i, state)}
							</TableRow>
						);
					})}
				</MuiTableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default TableWrapper;
