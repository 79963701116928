import MuiCheckbox from '@mui/material/Checkbox';

interface HeaderCheckBoxProps {
	checked: boolean;
	onChange: Function;
	hidden?: boolean;
	disabled?: boolean;
	indeterminate?: boolean;
}

const TableCheckBox = ({
	checked,
	onChange,
	disabled,
	hidden,
	indeterminate = false,
}: HeaderCheckBoxProps) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		onChange();
	if (hidden) return null;
	return (
		<MuiCheckbox
			indeterminate={indeterminate}
			disabled={disabled}
			checked={checked}
			onChange={handleChange}
		/>
	);
};

export default TableCheckBox;
