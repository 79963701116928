import { lazy } from 'react';
import isMobile from '~/utils/isMobile';

const Layout = lazy(() =>
	isMobile()
		? import('./mobile' /* webpackChunkName: 'Layout' */)
		: import('./desktop' /* webpackChunkName: 'Layout' */)
);

export default Layout;
