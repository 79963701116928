import { useRef, useState, useCallback, useEffect } from 'react';
import {
	getUnixTime,
	startOfDay,
	endOfDay,
	secondsToMilliseconds,
} from 'date-fns';
import DesktopDatePicker, {
	DesktopDatePickerProps,
} from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FormControl, FormHelperText } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useFormContext, Controller } from 'react-hook-form';
import FormLabel from './FormLabel';
import TextField from './TextField';

interface DatePickerProps
	extends Omit<DesktopDatePickerProps, 'value' | 'onChange' | 'renderInput'> {
	label?: string;
	name: string;
	styleSize?: string;
	tooltip?: string;
	required?: boolean;
	isUnixTime?: boolean;
	isStartDate?: boolean;
	isEndDate?: boolean;
	defaultLocale?: string;
	onChange?: Function;
}

const DatePicker = ({
	label,
	tooltip,
	required,
	name,
	styleSize,
	defaultLocale,
	isUnixTime,
	isStartDate,
	isEndDate,
	onChange,
	...otherProps
}: DatePickerProps) => {
	const { control } = useFormContext();
	const anchorEl = useRef<any>(null);
	const [locale, setLocale] = useState();
	const styles = useStyles(styleSize);
	const formateDate = useCallback(
		(value) => {
			if (typeof value === 'number' && isUnixTime) {
				return secondsToMilliseconds(value);
			}
			return value;
		},
		[isUnixTime]
	);
	useEffect(() => {
		import(`date-fns/locale/${defaultLocale}`).then((resp) => {
			setLocale(resp.default);
		});
	}, [defaultLocale]);
	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { onChange: onFieldChange, value = '' },
				fieldState: { error },
			}) => {
				const errorMsg = error?.message;
				const isError = !!errorMsg;
				return (
					<LocalizationProvider
						dateAdapter={AdapterDateFns as any}
						locale={locale}
					>
						<FormControl
							ref={anchorEl}
							error={isError}
							component='fieldset'
							sx={{ width: '100%' }}
						>
							<DesktopDatePicker
								mask='____/__/__'
								inputFormat='yyyy/MM/dd'
								label={
									<FormLabel
										required={required}
										label={label}
										tooltip={tooltip}
									/>
								}
								value={formateDate(value)}
								onChange={(value) => {
									let result = value ?? null;
									if (result) {
										if (isStartDate) result = startOfDay(result);
										if (isEndDate) result = endOfDay(result);
										result = result.getTime();
										if (isUnixTime) result = getUnixTime(result);
									}
									onChange ? onChange(result) : onFieldChange(result);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										error={isError}
										className={
											styleSize === 'small' ? 'Customized-small' : undefined
										}
										variant='standard'
									/>
								)}
								PopperProps={{
									anchorEl: () => anchorEl?.current,
								}}
								PaperProps={{
									sx: styles.paperSx,
								}}
								components={{
									OpenPickerIcon: CalendarMonthIcon,
								}}
								OpenPickerButtonProps={{
									sx: styles.pickerButtonSX,
								}}
								{...otherProps}
							/>
							<FormHelperText>{errorMsg}</FormHelperText>
						</FormControl>
					</LocalizationProvider>
				);
			}}
		/>
	);
};

DatePicker.defaultProps = {
	defaultLocale: 'zh-TW',
};

const useStyles = (styleSize?: string) => {
	const isSmall = styleSize === 'small';
	return {
		paperSx: {
			color: 'color.black',
			background: 'white',
			borderRadius: '8px',
			border: '1px solid',
			borderColor: 'color.background',
			boxShadow:
				'0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
			'& .PrivatePickersFadeTransitionGroup-root': {
				fontSize: '16px',
				lineHeight: '22px',
				fontWeight: 500,
			},
			'& .MuiSvgIcon-root': {
				color: 'color.black',
			},
			'& .MuiSvgIcon-root:hover': {
				bgcolor: 'color.background',
			},
			'& .MuiTypography-root': {
				color: 'color.black',
				fontSize: '14px',
				lineHeight: '20px',
			},
			'& .MuiButtonBase-root': {
				fontSize: '14px',
				lineHeight: '20px',
			},
			'& .MuiButtonBase-root:hover, .PrivatePickersYear-yearButton:hover': {
				bgcolor: 'color.background',
			},
			'& .MuiButtonBase-root.Mui-selected:hover, .MuiButtonBase-root.Mui-selected:focus, .PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersYear-yearButton.Mui-selected:focus':
				{
					bgcolor: 'color.black',
				},
		},
		pickerButtonSX: isSmall
			? { marginRight: -0.5, '& .MuiSvgIcon-root': { fontSize: '16px' } }
			: {
					marginRight: 0.5,
			  },
	};
};

export default DatePicker;
