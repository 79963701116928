import { lazy } from 'react';

const ShippersPage = lazy(
	() => import('./ShippersPage' /* webpackChunkName: 'ShippersPage' */)
);

const ShipperDetailPage = lazy(
	() =>
		import('./ShipperDetailPage' /* webpackChunkName: 'ShipperDetailPage' */)
);

const ShipperContracPage = lazy(
	() =>
		import(
			'./Contract/ShipperContracPage' /* webpackChunkName: 'ShipperContracPage' */
		)
);

export { ShippersPage, ShipperDetailPage, ShipperContracPage };
