const isLocalhost = !process.env.ENV_TYPE;
const domain = 'allytransport.com.tw';
export const defaultSubdomain = 'ally';
const appSubDomain = getAppSubDomain();

export const apiURL = isLocalhost
	? 'https://api.space-dev.allytransport.com.tw/restful'
	: `https://api.${appSubDomain}.${domain}/restful`;

export const imageURL = isLocalhost
	? 'https://space-dev.allytransport.com.tw/images'
	: `${window.location.protocol}//${window.location.hostname}/images`;

export const ssoURL =
	process.env.ENV_TYPE === 'production'
		? 'https://sso.auth.allytransport.com.tw'
		: 'https://sso.auth-dev.allytransport.com.tw';

export const ssoApiURL =
	process.env.ENV_TYPE === 'production'
		? 'https://api.auth.allytransport.com.tw/api'
		: 'https://api.auth-dev.allytransport.com.tw/api';

export const isPorterURL = process.env.REACT_APP_WEBSITE === 'porter';

export const isAdminURL = process.env.REACT_APP_WEBSITE === 'admin';

export const isCarrierURL = process.env.REACT_APP_WEBSITE === 'carrier';

export enum SYSTEM_TYPE_ENUM {
	ADMIN = 'ADMIN',
	SHIPPER = 'SHIPPER',
	CARRIER = 'CARRIER',
}

export const systemType = isPorterURL
	? SYSTEM_TYPE_ENUM.SHIPPER
	: isAdminURL
	? SYSTEM_TYPE_ENUM.ADMIN
	: SYSTEM_TYPE_ENUM.CARRIER;

export const subdomain = isLocalhost
	? defaultSubdomain
	: window.location.hostname.indexOf(appSubDomain) === 0
	? defaultSubdomain
	: window.location.hostname.split('.')[0];

function getAppSubDomain() {
	let tempSubdomain;
	switch (process.env.REACT_APP_WEBSITE) {
		case 'admin':
			tempSubdomain = 'space';
			break;
		case 'porter':
			tempSubdomain = 'shipper';
			break;
		case 'carrier':
			tempSubdomain = 'carrier';
			break;
		default:
			tempSubdomain = '';
	}
	if (isLocalhost || process.env.ENV_TYPE === 'staging') {
		tempSubdomain = `${tempSubdomain}-dev`;
	}
	return tempSubdomain;
}
