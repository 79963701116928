import { lazy } from 'react';

const SettingShipperPage = lazy(
	() =>
		import('./SettingShipperPage' /* webpackChunkName: 'SettingShipperPage' */)
);

const SettingCarrierPage = lazy(
	() =>
		import('./SettingCarrierPage' /* webpackChunkName: 'SettingCarrierPage' */)
);

export { SettingShipperPage, SettingCarrierPage };
