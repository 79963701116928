import React from 'react';
import TablePagination, {
	TablePaginationBaseProps,
} from '@mui/material/TablePagination';
import { Box } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface PaginationProps extends TablePaginationBaseProps {
	searchData: any;
	onQuery: any;
}

const Pagination = ({ searchData, onQuery, ...props }: PaginationProps) => {
	const { t } = useTranslation();

	if (searchData.total < 0) {
		return null;
	}

	return (
		<TablePagination
			labelRowsPerPage={`${t('perPage')}:`}
			labelDisplayedRows={({ from, to, count }) => ''}
			rowsPerPageOptions={[]}
			component='div'
			count={searchData.total}
			rowsPerPage={searchData.limit}
			page={searchData.pageIndex}
			onPageChange={(event, newPage) => {
				onQuery({ offset: newPage * searchData.limit });
			}}
			ActionsComponent={TablePaginationActions as (props: any) => any}
			{...props}
		/>
	);
};

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	textVariant: TypographyProps['variant'];
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number
	) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const styles = useStyles(theme);
	const { count, page, rowsPerPage, onPageChange, textVariant } = props;

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1);
	};

	return (
		<Box sx={styles.actionsContainer}>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
				sx={styles.iconButtton}
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<Typography variant={textVariant} sx={styles.pageText}>{`${
				page * rowsPerPage + 1
			} - ${
				count < (page + 1) * rowsPerPage ? count : (page + 1) * rowsPerPage
			} ${t('paginationText', { number: count })}`}</Typography>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'
				sx={styles.iconButtton}
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
		</Box>
	);
};

export default Pagination;

const useStyles = (theme: any) => ({
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		bgcolor: 'color.background',
		borderRadius: '8px',
	},
	pageText: {
		color: 'color.black50',
		px: 6,
	},
	iconButtton: {
		color: 'color.black',
		'&.Mui-disabled': {
			'& .MuiSvgIcon-root': {
				color: 'color.black20',
			},
		},
	},
});

TablePaginationActions.defaultProps = {
	textVariant: 'body2',
};
